import { createFeature, createReducer, on } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { environment } from '../../../../environments/environment';
import { toggleValueInArrayWithLimit } from '../../../core/helpers/helpers';
import { MyEventsContainerActions } from '../../../core/store/actions';
import { eventFormSteps } from '../../upload-event/models/models';
import { EditEventState } from './edit-event.state';
import { EditEventContainerActions, EditEventEffectsActions } from './index';
import { mapEventToEventInformation } from '../../../core/helpers/mappers';

export const initialState: Readonly<EditEventState> = {
  currentStep: 'eventInformation',
  eventInformation: {
    name: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
    startTime: '',
    endTime: '',
    fromPrice: '',
    pressText: '',
    ticketLink: '',
    ageRestrictionWomen: '',
    ageRestrictionMen: '',
    fyraTicketLink: '',
    location: 0,
    internalLabel: -1,
    locationName: '',
    locationStreet: '',
    locationPlz: '',
    locationProvince: '',
    eventId: undefined,
    genres: [],
    tags: [],
    flyer: null,
  },
  products: {
    flyer: {
      id: environment.production
        ? 'price_1McbgAGa89ImEHjikiSQIN0p'
        : 'price_1OyLF6Ga89ImEHjiInJGgCbh',
      key: 'flyer',
      name: 'Flyer',
      description: '',
      price: 0,
      selected: false,
      expanded: false,
      disabled: false,
      icon: 'https://a.storyblok.com/f/158785/687x687/b8698535ac/image.png?cv=1682497831710',
    },
    mark: {
      id: environment.production
        ? 'price_1NudBZGa89ImEHjiiPqKEFTY'
        : 'price_1OEzgNGa89ImEHjiv0RmhjL1',
      key: 'mark',
      name: 'Event Markieren',
      description:
        'Mit dem Begriff PIN ist gemeint, dass bestehende Events in einer jeweiligen Filterung zuoberst gepinnt und markiert werden.',
      price: 29,
      selected: false,
      expanded: false,
      disabled: false,
      icon: 'https://a.storyblok.com/f/158785/694x694/65595aff56/highlight.png?cv=1682497831603',
    },
    pin: {
      id: environment.production
        ? 'price_1MbswvGa89ImEHjig0bPLui7'
        : 'price_1OEzeoGa89ImEHjitYuN9MT9',
      key: 'pin',
      name: 'Event PIN',
      description:
        'Mit dem Begriff PIN ist gemeint, dass bestehende Events in einer jeweiligen Filterung zuoberst gepinnt und markiert werden.',
      price: 49,
      selected: false,
      expanded: false,
      disabled: false,
      icon: 'https://a.storyblok.com/f/158785/606x692/ee70b86570/pin.png?cv=1682497831713',
    },
  },
  eventFormSteps,
  selectedGenres: [],
  selectedTags: [],
  originalImage: '',
};

export const editEventReducers = createReducer(
  initialState,
  on(
    EditEventContainerActions.eventInformationFormChanged,
    (state, { eventInformation }) => ({
      ...state,
      eventInformation: {
        ...state.eventInformation,
        name: eventInformation.name,
        startDate: eventInformation.startDate,
        endDate: eventInformation.endDate,
        startTime: eventInformation.startTime,
        endTime: eventInformation.endTime,
        internalLabel: eventInformation.internalLabel,
        location: eventInformation.location,
        locationName: eventInformation.locationName,
        locationStreet: eventInformation.locationStreet,
        locationPlz: eventInformation.locationPlz,
        locationProvince: eventInformation.locationProvince,
        ageRestrictionWomen: eventInformation.ageRestrictionWomen,
        ageRestrictionMen: eventInformation.ageRestrictionMen,
        pressText: eventInformation.pressText,
      },
    }),
  ),
  on(EditEventContainerActions.flyerCropped, (state, { flyer }) => ({
    ...state,
    eventInformation: {
      ...state.eventInformation,
      flyer,
    },
  })),
  on(EditEventContainerActions.toggleGenreClicked, (state, { genreId }) => ({
    ...state,
    selectedGenres: toggleValueInArrayWithLimit(
      state.selectedGenres,
      genreId,
      3,
    ),
  })),
  on(EditEventContainerActions.toggleTagClicked, (state, { tagId }) => ({
    ...state,
    selectedTags: toggleValueInArrayWithLimit(state.selectedTags, tagId, 3),
  })),
  on(
    EditEventContainerActions.productSelectionToggled,
    (state, { product }) => ({
      ...state,
      products: {
        ...state.products,
        [product.key]: {
          ...state.products[product.key],
          selected: !state.products[product.key]!.selected,
          expanded:
            state.products[product.key]!.expanded ||
            !state.products[product.key]!.selected,
        },
      },
    }),
  ),
  on(
    EditEventContainerActions.eventTicketingFormChanged,
    (state, { eventTicketing }) => ({
      ...state,
      eventInformation: {
        ...state.eventInformation,
        fromPrice: eventTicketing.fromPrice,
        ticketLink: eventTicketing.ticketLink,
        fyraTicketLink: eventTicketing.fyraTicketLink,
      },
    }),
  ),
  on(EditEventContainerActions.flyerUploaded, (state, { originalImage }) => ({
    ...state,
    originalImage,
  })),
  on(
    EditEventEffectsActions.editEventFormInvalid,
    (state, { invalidSteps }) => ({
      ...state,
      eventFormSteps: Object.fromEntries(
        Object.entries(state.eventFormSteps).map(([key, value]) => [
          key,
          {
            ...value,
            state: (key === 'overview'
              ? 'unvalidated'
              : invalidSteps.includes(key)
                ? 'invalid'
                : 'valid') as 'unvalidated' | 'valid' | 'invalid',
          },
        ]),
      ),
    }),
  ),
  on(EditEventContainerActions.setStep, (state, { stepId }) => {
    const updatedSteps = { ...state.eventFormSteps };

    updatedSteps[stepId] = { ...updatedSteps[stepId], active: true };

    const previouslyActiveStep = Object.keys(updatedSteps).find(
      (key) =>
        updatedSteps[key].active === true &&
        updatedSteps[key].stepId !== stepId,
    );
    if (previouslyActiveStep) {
      updatedSteps[previouslyActiveStep] = {
        ...updatedSteps[previouslyActiveStep],
        active: false,
      };
    }

    return { ...state, eventFormSteps: updatedSteps };
  }),
  on(
    EditEventEffectsActions.stepOneFormValid,
    EditEventEffectsActions.stepTwoFormValid,
    EditEventEffectsActions.stepThreeFormValid,
    EditEventEffectsActions.stepFourFormValid,
    (state) => {
      const updatedSteps = { ...state.eventFormSteps };
      const keys = Object.keys(updatedSteps);
      const activeStep = Object.keys(updatedSteps).find(
        (key) => updatedSteps[key].active,
      );
      const currentItemIndex = keys.indexOf(activeStep!);

      if (currentItemIndex < 4) {
        const previousKey = keys[currentItemIndex + 1];
        updatedSteps[previousKey] = {
          ...updatedSteps[previousKey],
          active: true,
        };
      }

      updatedSteps[activeStep!] = {
        ...updatedSteps[activeStep!],
        active: false,
        state: 'valid',
      };
      return { ...state, eventFormSteps: updatedSteps };
    },
  ),
  on(EditEventContainerActions.lastStepClicked, (state) => {
    const updatedSteps = { ...state.eventFormSteps };
    const keys = Object.keys(updatedSteps);
    const activeStep = Object.keys(updatedSteps).find(
      (key) => updatedSteps[key].active,
    );
    const currentItemIndex = keys.indexOf(activeStep!);

    if (currentItemIndex > 0) {
      const previousKey = keys[currentItemIndex - 1];
      updatedSteps[previousKey] = {
        ...updatedSteps[previousKey],
        active: true,
      };
    }

    updatedSteps[activeStep!] = { ...updatedSteps[activeStep!], active: false };
    return { ...state, eventFormSteps: updatedSteps };
  }),
  on(
    EditEventEffectsActions.stepOneFormInvalid,
    EditEventEffectsActions.stepTwoFormInvalid,
    EditEventEffectsActions.stepThreeFormInvalid,
    EditEventEffectsActions.stepFourFormInvalid,
    (state) => {
      const updatedSteps = { ...state.eventFormSteps };
      const activeStep = Object.keys(updatedSteps).find(
        (key) => updatedSteps[key].active,
      );

      updatedSteps[activeStep!] = {
        ...updatedSteps[activeStep!],
        state: 'invalid',
      };
      return { ...state, eventFormSteps: updatedSteps };
    },
  ),
  on(MyEventsContainerActions.editEventClicked, (state, { event }) => ({
    ...state,
    currentStep: 'eventInformation',
    eventInformation: mapEventToEventInformation(event),
    products: {
      flyer: {
        ...state.products['flyer'],
        selected: event.flyerActive,
        expanded: false,
        alreadySelected: event.flyerActive,
      },
      mark: {
        ...state.products['mark'],
        selected: event.highlightActive,
        expanded: false,
        alreadySelected: event.highlightActive,
      },
      pin: {
        ...state.products['pin'],
        selected: event.pinActive,
        expanded: false,
        alreadySelected: event.pinActive,
      },
    },
    eventFormSteps,
    selectedGenres: event.genres.map((genre) => genre.id),
    selectedTags: event.tags.map((tag) => tag.id),
  })),
);

export const editEventFeature = createFeature({
  name: 'editEvent',
  reducer: editEventReducers,
});
