import { createAction, props } from '@ngrx/store';

export const stepOneFormValid = createAction(
  '[Edit Event Effects] Step One Form Valid',
);
export const stepTwoFormValid = createAction(
  '[Edit Event Effects] Step Two Form Valid',
);
export const stepThreeFormValid = createAction(
  '[Edit Event Effects] Step Three Form Valid',
);
export const stepFourFormValid = createAction(
  '[Edit Event Effects] Step Four Form Valid',
);
export const stepOneFormInvalid = createAction(
  '[Edit Event Effects] Step One Form Invalid',
);
export const stepTwoFormInvalid = createAction(
  '[Edit Event Effects] Step Two Form Invalid',
);
export const stepThreeFormInvalid = createAction(
  '[Edit Event Effects] Step Three Form Invalid',
);
export const stepFourFormInvalid = createAction(
  '[Edit Event Effects] Step Four Form Invalid',
);
export const editEventSuccessful = createAction(
  '[Edit Event Effects] Edit Event Successful',
);
export const editEventFromStorageSuccessful = createAction(
  '[Edit Event Effects] Edit Event From Storage Successful',
);
export const editEventUnsuccessful = createAction(
  '[Edit Event Effects] Edit Event Unsuccessful',
);
export const editEventFromStorageUnsuccessful = createAction(
  '[Edit Event Effects] Edit Event From Storage Unsuccessful',
);
export const editEventStarted = createAction(
  '[Edit Event Effects] Edit Event Started',
);
export const editEventPostponed = createAction(
  '[Edit Event Effects] Edit Event Postponed',
);
export const editEventSuccessfulWithPayment = createAction(
  '[Edit Event Effects] Edit Event Successful With Payment',
);
export const paymentStartedSuccessful = createAction(
  '[Edit Event Effects] Payment Started Successful',
  props<{
    paymentId: string;
  }>(),
);
export const paymentStartedUnsuccessful = createAction(
  '[Edit Event Effects] Payment Started Unsuccessful',
);
export const editEventFormInvalid = createAction(
  '[Edit Event Effects] Edit Event Form Invalid',
  props<{
    invalidSteps: string[];
  }>(),
);
export const formStepsValid = createAction(
  '[Edit Event Container] Form Steps Valid',
);
