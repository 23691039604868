import * as EditEventContainerActions from './actions/edit-event-container.actions';
import * as EditEventEffectsActions from './actions/edit-event-effects.actions';
import * as EditCancelledGuardActions from './actions/edit-cancelled-guard.actions';
import * as EditSuccessfulGuardActions from './actions/edit-successful-guard.actions';

import {editEventFeature} from './edit-event.reducers';

export * as editEventState from './edit-event.state';

const {reducer, name, ...editEventSelectors} = editEventFeature;

export {
  reducer as editEventReducer,
  name as editEventName,
  editEventSelectors,
  EditEventContainerActions,
  EditEventEffectsActions,
  EditCancelledGuardActions,
  EditSuccessfulGuardActions
};
